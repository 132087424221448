import { createAction } from 'redux-act';
//////////////////////////////////////////////////

export const selectItem = createAction('selectItem');
export const setReports = createAction('setReports');
export const setVisited = createAction('setVisited');
export const addPayrolls = createAction('addPayrolls');
export const setUsedReport = createAction('setUsedReport');
export const setListLoading = createAction('setListLoading');
export const setReportPending = createAction('setReportPending');
export const cleanQuickFilter = createAction('cleanQuickFilter');
export const getPayrollDetails = createAction('getPayrollDetails');
export const setTableTitleSort = createAction('setTableTitleSort');
export const getItemListRequest = createAction('getItemListRequest');
export const getItemListSuccess = createAction('getItemListSuccess');
export const startVendorPayroll = createAction('startVendorPayroll');
export const getPayrollStatistic = createAction('getPayrollStatistic');
export const setTableTitleFilter = createAction('setTableTitleFilter');
export const printPayrollRequest = createAction('printPayrollRequest');
export const createReportRequest = createAction('createReportRequest');
export const updateReportRequest = createAction('updateReportRequest');
export const togglePayrollDetails = createAction('togglePayrollDetails');
export const setQuickFilterParams = createAction('setQuickFilterParams');
export const getPayrollXMLRequest = createAction('getPayrollXMLRequest');
export const printByReportRequest = createAction('printByReportRequest');
export const chaseCSVExportRequest = createAction('chaseCSVExportRequest');
export const updatePayrollInvoices = createAction('updatePayrollInvoices');
export const getXMLByReportRequest = createAction('getXMLByReportRequest');
export const exportStatisticRequest = createAction('exportStatisticRequest');
export const getPayrollDetailsError = createAction('getPayrollDetailsError');
export const resetListAndPagination = createAction('resetListAndPagination');
export const sendToQuickBookRequest = createAction('sendToQuickBookRequest');
export const exportReportDataRequest = createAction('exportReportDataRequest');
export const getXmlFileByTruckRequest = createAction('getXmlFileByTruckRequest');
export const setPayrollDetailsLoading = createAction('setPayrollDetailsLoading');
export const getPayrollDetailsSuccess = createAction('getPayrollDetailsSuccess');
export const removeInvoiceFromPayroll = createAction('removeInvoiceFromPayroll');
export const removeVendorPayrollFromUI = createAction('removeVendorPayrollFromUI');
export const setPayrollStatisticLoading = createAction('setPayrollStatisticLoading');
export const getPayrollStatisticSuccess = createAction('getPayrollStatisticSuccess');
export const updateVendorInvoiceSuccess = createAction('updateVendorInvoiceSuccess');
export const updateVendorInvoiceRequest = createAction('updateVendorInvoiceRequest');
export const handleUpdatePayrollRequest = createAction('handleUpdatePayrollRequest');
export const removePayrollInvoiceFromUI = createAction('removePayrollInvoiceFromUI');
export const changeDefaultReportRequest = createAction('changeDefaultReportRequest');
export const deleteVendorPayrollsRequest = createAction('deleteVendorPayrollsRequest');
export const createVendorPayrollsRequest = createAction('createVendorPayrollsRequest');
export const getDriverRatesXmlFileRequest = createAction('getDriverRatesXmlFileRequest');
export const collapseAndResetPayrollInvoices = createAction('collapseAndResetPayrollInvoices');
export const getPayrollByReportAndUpdateOnListRequest = createAction('getPayrollByReportAndUpdateOnListRequest');
