import * as R from 'ramda';
import React from 'react';
// helpers/constants
import * as G from '../../../helpers';
// icons
import * as I from '../../../svgs';
// ui
import { Box, Flex, FlexHovered, ActionButton } from '../../../ui';
//////////////////////////////////////////////////

const blueColor = G.getTheme('colors.bgBlue');
const whiteColor = G.getTheme('colors.white');
const darkBlueColor = G.getTheme('colors.dark.blue');
const darkGreyColor = G.getTheme('colors.dark.grey');

const PrevBtn = (props: Object) => (
  <FlexHovered
    p='5px 10px'
    fontSize={15}
    cursor='pointer'
    borderRadius='8px'
    bgColor={blueColor}
    color={darkBlueColor}
    display='inline-flex'
    transition='all 0.5s'
    justifyContent='flex-end'
    onClick={props.handleClickPrev}
  >
    <Box transform='rotate(180deg)'>
      {I.renderSimpleRightArrowIcon(darkBlueColor)}
    </Box>
    <Box ml={10}>
      {G.getWindowLocale('actions:previous', 'Previous')}
    </Box>
  </FlexHovered>
);

const getNextBtnText = (text: string) => G.ifElse(
  G.isNotNilAndNotEmpty(text),
  text,
  G.getWindowLocale('actions:validate-next', 'Validate/Next'),
);

const NextBtn = (props: Object) => (
  <FlexHovered
    p='5px 10px'
    fontSize={15}
    cursor='pointer'
    borderRadius='8px'
    bgColor={blueColor}
    color={darkBlueColor}
    display='inline-flex'
    transition='all 0.5s'
    justifyContent='flex-end'
    onClick={props.submitForm}
  >
    <Box mr={10}>
      {getNextBtnText(props.nextBtnText)}
    </Box>
    {I.renderSimpleRightArrowIcon(darkBlueColor)}
  </FlexHovered>
);

const SaveBillTo = ({
  handleSaveBillTo,
  disableSaveBillTo,
}: Object) => (
  <ActionButton
    p='4px 8px'
    height={32}
    type='submit'
    fontSize={14}
    borderRadius={5}
    bgColor={whiteColor}
    textColor={darkBlueColor}
    onClick={handleSaveBillTo}
    disabled={disableSaveBillTo}
    border={`1px solid ${darkBlueColor}`}
  >
    {G.getWindowLocale('actions:save-bill-to', 'Save Bill To')}
  </ActionButton>
);

const PrevNextAction = (props: Object) => {
  const { showNext, handleClickPrev, disableSaveBillTo } = props;

  const showSaveBillButton = G.isNotNil(disableSaveBillTo);
  const withLeftButton = R.or(handleClickPrev, showSaveBillButton);

  return (
    <Flex
      p={10}
      borderTop='1px solid'
      mt={R.or(props.mt, 0)}
      borderColor={darkGreyColor}
      justifyContent={G.ifElse(withLeftButton, 'space-between', 'flex-end')}
    >
      {showSaveBillButton && <SaveBillTo {...props} />}
      {handleClickPrev && <PrevBtn {...props} />}
      {showNext && <NextBtn {...props} />}
    </Flex>
  );
};

export default PrevNextAction;
