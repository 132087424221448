import React from 'react';
import * as R from 'ramda';
import { withFormik } from 'formik';
import { OuterClick } from 'react-outer-click';
import { pure, compose, withHandlers } from 'react-recompose';
// helpers/constants
import * as G from '../../../helpers';
// ui
import { Box } from '../../../ui';
// feature new-do
import FormTab from './form-tab';
import { Fieldset } from './formik/fieldset';
import PrevNextAction from './validate-action';
import ReferencesArray from './references-array';
import SectionDivider from '../components/section-divider';
import { FORM_BOX_SHADOW, TAB_NAME_PRICING } from '../constants';
import { isValidReferencesForm, validationSchemaReferencesForm } from '../validation';
import {
  noteFields,
  mainReferenceFields,
  specialInstructionsRefFields,
  internalInstructionsRefFields,
} from '../settings/fields-settings';
//////////////////////////////////////////////////

const ReferenceForm = (props: Object) => (
  <OuterClick onOuterClick={props.handleOuterClick}>
    <form>
      <FormTab {...props} />
      <Box boxShadow={FORM_BOX_SHADOW}>
        <Box pt={20}>
          <Fieldset
            {...props}
            fields={mainReferenceFields}
          />
          <SectionDivider
            text={G.getWindowLocale(
              'titles:special-instructions',
              'Special Instructions',
              { caseAction: 'upperCase' },
            )}
          />
          <Fieldset
            {...props}
            fields={specialInstructionsRefFields}
          />
          <SectionDivider
            text={G.getWindowLocale(
              'titles:internal-instructions',
              'Internal Instructions',
              { caseAction: 'upperCase' },
            )}
          />
          <Fieldset
            {...props}
            fields={internalInstructionsRefFields}
          />
          <SectionDivider
            text={G.getWindowLocale(
              'titles:note',
              'Note',
              { caseAction: 'upperCase' },
            )}
          />
          <Fieldset
            {...props}
            fields={noteFields}
          />
          <ReferencesArray {...props} />
          <PrevNextAction
            showNext={true}
            submitForm={props.submitForm}
            handleClickPrev={props.handleClickPrev}
          />
        </Box>
      </Box>
    </form>
  </OuterClick>
);

const enhance = compose(
  withFormik({
    enableReinitialize: true,
    validationSchema: validationSchemaReferencesForm,
    mapPropsToValues: ({ initialValues }: Object) => initialValues,
    handleSubmit: (values: Object, { props }: Object) => {
      const { setActiveLeftTab, setFormDataToStore } = props;
      setFormDataToStore({ dataName: 'referenceFormData', formData: R.assoc('isValid', true, values) });
      setActiveLeftTab(TAB_NAME_PRICING);
    },
    displayName: 'ReferenceForm',
  }),
  withHandlers({
    onClickTabCallback: (props: Object) => (activeName: Object) => {
      const { values, setActiveLeftTab, setFormDataToStore } = props;
      const isValid = isValidReferencesForm(values);
      setFormDataToStore({ dataName: 'referenceFormData', formData: R.assoc('isValid', isValid, values) });
      setActiveLeftTab(activeName);
    },
    handleClickPrev: (props: Object) => () => {
      const { stops, values, setActiveLeftTab, setFormDataToStore } = props;
      const isValid = isValidReferencesForm(values);
      setFormDataToStore({ dataName: 'referenceFormData', formData: R.assoc('isValid', isValid, values) });
      setActiveLeftTab(R.length(R.values(stops)));
    },
    handleOuterClick: (props: Object) => () => {
      const { values, setFormDataToStore } = props;
      const isValid = isValidReferencesForm(values);
      setFormDataToStore({ dataName: 'referenceFormData', formData: R.assoc('isValid', isValid, values) });
    },
  }),
  pure,
);

export default enhance(ReferenceForm);
