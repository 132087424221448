// features
import { FILTER_PARAMS as fuelCardFilterProps } from '../../fuel-cards/settings/filter-params';
import { loadFilterProps, orderFilterProps } from '../../dispatch-report/settings/filter-params';
import { getFilterParamsByReportType as getFleetFilterPropsByReportType } from '../../fleet-list/settings';
import { FILTER_PARAMS as customerInvoiceFilterProps } from '../../invoice/customer/settings/filter-params';
import { FILTER_PARAMS as routeByLoadFilterProps } from '../../dispatch-report/route/route-by-load/settings';
import { driverChargesFilterProps, vendorChargesFilterProps } from '../../payroll/settings/charges-table-settings';
import {
  FILTER_PARAMS as driverPayrollFilterProps,
  VENDOR_FILTER_PARAMS as vendorPayrollFilterProps,
} from '../../payroll/settings/filter-params';
// helpers/constants
import * as GC from '../../../constants';
//////////////////////////////////////////////////

export const filterPropsMap = {
  [GC.TEL_REPORT]: loadFilterProps,
  [GC.CLO_REPORT]: orderFilterProps,
  [GC.PIVOT_TEL_REPORT]: loadFilterProps,
  [GC.PIVOT_CLO_REPORT]: orderFilterProps,
  [GC.PIVOT_FUEL_CARD_REPORT]: fuelCardFilterProps,
  [GC.PIVOT_ROUTE_BY_LOAD_REPORT]: routeByLoadFilterProps,
  [GC.PIVOT_DRIVER_PAYROLL_REPORT]: driverPayrollFilterProps,
  [GC.PIVOT_VENDOR_PAYROLL_REPORT]: vendorPayrollFilterProps,
  [GC.PIVOT_PAYROLL_CHARGES_REPORT]: driverChargesFilterProps,
  [GC.PIVOT_CUSTOMER_INVOICE_REPORT]: customerInvoiceFilterProps,
  [GC.PIVOT_VENDOR_PAYROLL_CHARGES_REPORT]: vendorChargesFilterProps,
  [GC.PIVOT_FLEET_EQUIPMENT_SERVICE_REPORT]: getFleetFilterPropsByReportType(GC.FLEET_EQUIPMENT_SERVICE_REPORT),
};
