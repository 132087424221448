import * as R from 'ramda';
import React, { Fragment } from 'react';
// components
import { TextComponent } from '../../../components/text';
// features
import { AuthWrapper } from '../../permission';
import PC from '../../permission/role-permission';
import { getTotalDistanceFromValues } from '../../rate/helpers';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// ui
import { Box, Flex, SectionsDivider } from '../../../ui';
// feature new-do
import SectionDivider from './section-divider';
import {
  mapReferencesWithRefTypes,
  getMappedServicesFromConfigsByOptionGuid,
  getMappedEquipmentFromConfigsByOptionGuid,
} from '../helpers';
//////////////////////////////////////////////////

const InfoSection = ({ infoText, dividerText }: Object) => (
  <Box width='100%'>
    <SectionDivider text={dividerText} />
    <Box px={15} mb={15} wordBreak='break-all'>{infoText}</Box>
  </Box>
);

const ArraySection = ({ items, dividerText }: Object) => (
  <Box width='100%'>
    <SectionDivider text={dividerText} />
    {
      items.map((item: Object, index: Object) => (
        <Flex mb='5px' px={15} key={`${item}${index}`}>
          <TextComponent title={item} withEllipsis={true}>
            {item}
          </TextComponent>
        </Flex>
      ))
    }
  </Box>
);

const RateInfo = ({ title, total }: Object) => (
  <Flex mt={10}>
    <Box mr='5px'> {title}:</Box>
    <TextComponent title={total} maxWidth={175} withEllipsis={true}>{total}</TextComponent>
  </Flex>
);

const getMargin = (orderTotal: number, tripTotal: number) => G.mathRoundNumber(R.subtract(orderTotal, tripTotal));

const getRpm = (total: number, totalDistance: number) => {
  if (R.equals(totalDistance, 0)) return 0;

  return G.mathRoundNumber(R.divide(total, totalDistance));
};

const DispatchRpmInfo = (props: Object) => {
  const {
    rate,
    tripTotal,
    driverRate,
    orderTotal,
    defaultUom,
    orderRateCurrencySymbol,
    tripDistanceWithDeadhead,
  } = props;

  const orderRateCurrency = G.getCurrencyFromRate(rate);
  const tripRateCurrency = G.getCurrencyFromRate(driverRate);

  let margin = `${orderRateCurrencySymbol} ${getMargin(orderTotal, tripTotal)}`;

  if (G.notEquals(tripRateCurrency, orderRateCurrency)) {
    const {
      normalizedAmount: normalizedTripTotal,
    } = G.convertToNormalizedCurrencyByExchangeRate(tripTotal, tripRateCurrency);

    const {
      normalizedCurrency,
      normalizedAmount: normalizedOrderTotal,
    } = G.convertToNormalizedCurrencyByExchangeRate(orderTotal, orderRateCurrency);

    margin = `${G.getCurrencySymbol(normalizedCurrency)} ${getMargin(normalizedOrderTotal, normalizedTripTotal)}`;
  }

  const dispatchRpm = `${G.getWindowLocale('titles:dispatch', 'Dispatch')} ${G.getRpmText(defaultUom)}: ${
    orderRateCurrencySymbol} ${getRpm(orderTotal, tripDistanceWithDeadhead)}`;

  return (
    <Fragment>
      <SectionsDivider borderColor={G.getTheme('colors.lightGrey')} />
      <Flex
        px={15}
        fontWeight={700}
        margin='10px auto 0px'
        flexDirection='column'
        color={G.getTheme('colors.light.blue')}
      >
        <Box>{dispatchRpm}</Box>
        <Box mt='8px'>{`${G.getWindowLocale('titles:margin', 'Margin')}: ${margin}`}</Box>
      </Flex>
    </Fragment>
  );
};

const getChargesTotalFromRateWithCurrency = (rate: Object, currencySymbol: string) => (
  `${currencySymbol} ${G.getCalcRateChargesTotal(G.getChargesFromObject(rate), G.getCurrencyFromObject2(rate))}`
);

const OrderRateSection = ({ rate, orderTotal, orderRateCurrencySymbol }: Object) => {
  const orderTotalDistanceUom = G.getPropFromObject(GC.FIELD_TOTAL_TRIP_DISTANCE_UOM, rate);
  const orderTotalDistance = G.NaNToZero(G.toNumber(G.getPropFromObject(GC.FIELD_TOTAL_TRIP_DISTANCE, rate)));

  const orderRpm = getRpm(orderTotal, orderTotalDistance);

  return (
    <Fragment>
      <RateInfo
        title={G.getWindowLocale('titles:rate-total', 'Rate Total')}
        total={getChargesTotalFromRateWithCurrency(rate, orderRateCurrencySymbol)}
      />
      <RateInfo
        title={G.getWindowLocale('titles:total-distance', 'Total Distance')}
        total={`${orderTotalDistance} ${G.getUomLocale(orderTotalDistanceUom, 'abbreviation')}`}
      />
      <RateInfo
        title={G.getRpmText(orderTotalDistanceUom)}
        total={`${orderRateCurrencySymbol} ${orderRpm}`}
      />
    </Fragment>
  );
};

const DriverRateSection = ({ tripTotal, driverRate, defaultUom, tripDistanceWithDeadhead }: Object) => (
  <AuthWrapper has={[PC.FLEET_RATE_READ, PC.FLEET_RATE_WRITE]} >
    <Flex width={290} flexDirection='column' alignItems='flex-start'>
      <RateInfo
        total={G.getTelChargesTotalInfo(driverRate)}
        title={`${G.getWindowLocale('titles:driver-rate', 'Driver Rate')} ${
          G.getWindowLocale('titles:total', 'Total')
        }`}
      />
      <RateInfo
        title={G.getWindowLocale('titles:total-distance', 'Total Distance')}
        total={`${tripDistanceWithDeadhead} ${G.getUomLocale(defaultUom, 'abbreviation')}`}
      />
      <RateInfo
        title={G.getRpmText(defaultUom)}
        total={`${G.getCurrencySymbolFromRate(driverRate)} ${getRpm(tripTotal, tripDistanceWithDeadhead)}`}
      />
    </Flex>
  </AuthWrapper>
);

const getOrderRateCommonInfo = (rate: Object) => {
  let orderTotal = 0;
  let orderRateCurrencySymbol = '';

  if (G.isNotNilAndNotEmpty(rate)) {
    orderRateCurrencySymbol = G.getCurrencySymbolFromRate(rate);
    orderTotal = G.getCalcRateChargesTotal(G.getChargesFromObject(rate), G.getCurrencyFromObject2(rate));
  }

  return { orderTotal, orderRateCurrencySymbol };
};

const getDriverRateCommonInfo = (driverRate: Object) => {
  let tripTotal = 0;
  let tripDistanceWithDeadhead = 0;

  if (G.isNotNilAndNotEmpty(driverRate)) {
    tripTotal = G.calcTelRateTotal(driverRate);
    tripDistanceWithDeadhead = G.toNumber(getTotalDistanceFromValues(R.or(driverRate, {})));
  }

  return { tripTotal, tripDistanceWithDeadhead };
};

const RateSection = (props: Object) => {
  const { rate, driverRate, carrierRate, telCreationMode } = props;

  if (G.isAllNilOrEmpty([rate, driverRate, carrierRate])) return null;

  const { orderTotal, orderRateCurrencySymbol } = getOrderRateCommonInfo(rate);
  const { tripTotal, tripDistanceWithDeadhead } = getDriverRateCommonInfo(driverRate);

  const defaultUomSystem = G.getConfigGeneralUomCalcDefaultUomSystemFromWindow();
  const defaultUom = R.pathOr(GC.UOM_MILE, [defaultUomSystem], GC.uomSystemToDistanceUomMap);

  const isSingleTelMode = R.or(R.isNil(telCreationMode), R.equals(telCreationMode, GC.TEL_CREATION_MODE_SINGLE_TEL));

  return (
    <Flex
      mt={15}
      pb={10}
      width='100%'
      flexDirection='column'
      alignItems='flex-start'
      bg={G.getTheme('colors.white')}
    >
      <Flex
        px={35}
        height={32}
        width='100%'
        borderTopLeftRadius='6px'
        borderTopRightRadius='6px'
        justifyContent='space-between'
        bg={G.getTheme('colors.lightGrey')}
        color={G.getTheme('colors.darkGrey')}
      >
        <Box width={290}>{G.getWindowLocale('titles:clo', 'Order')}</Box>
        {
          R.and(isSingleTelMode, G.isOneNotNilOrNotEmpty([driverRate, carrierRate])) &&
          <Box width={290}>{G.getWindowLocale('titles:tel', 'Trip')}</Box>
        }
      </Flex>
      <Flex p='5px 35px' width='100%' justifyContent='space-between'>
        {
          <Flex width={290} flexDirection='column' alignItems='flex-start'>
            {
              G.isNotNilAndNotEmpty(rate) &&
              <OrderRateSection
                rate={rate}
                orderTotal={orderTotal}
                orderRateCurrencySymbol={orderRateCurrencySymbol}
              />
            }
          </Flex>
        }
        {
          R.and(isSingleTelMode, G.isNotNilAndNotEmpty(carrierRate)) &&
          <AuthWrapper width='290px' has={[PC.CARRIER_RATE_READ, PC.CARRIER_RATE_WRITE]}>
            <RateInfo
              total={G.getTelChargesTotalInfo(carrierRate)}
              title={`${G.getWindowLocale('titles:carrier-rate', 'Carrier Rate')}
                ${G.getWindowLocale('titles:total', 'Total')}`}
            />
          </AuthWrapper>
        }
        {
          R.and(isSingleTelMode, G.isNotNilAndNotEmpty(driverRate)) &&
          <DriverRateSection
            tripTotal={tripTotal}
            driverRate={driverRate}
            defaultUom={defaultUom}
            tripDistanceWithDeadhead={tripDistanceWithDeadhead}
          />
        }
      </Flex>
      {
        R.and(isSingleTelMode, G.isAllNotNilOrNotEmpty([rate, driverRate])) &&
        <DispatchRpmInfo
          rate={rate}
          tripTotal={tripTotal}
          orderTotal={orderTotal}
          driverRate={driverRate}
          defaultUom={defaultUom}
          orderRateCurrencySymbol={orderRateCurrencySymbol}
          tripDistanceWithDeadhead={tripDistanceWithDeadhead}
        />
      }
    </Flex>
  );
};

const getReferences = (props: Object) => {
  const { branchRefTypes, referenceFormData, customerRefTypeName, customerReferenceValue } = props;

  const { references } = referenceFormData;
  const mappedReferences = R.map(
    (item: Object) => `${item.name}: ${item.value}`,
    R.reject(
      (item: Object) => R.or(G.isNilOrEmpty(item.value), G.isNilOrEmpty(item.name)),
      mapReferencesWithRefTypes(references, branchRefTypes),
    ),
  );

  if (G.isNotNilAndNotEmpty(customerReferenceValue)) {
    return R.prepend(`${customerRefTypeName}: ${customerReferenceValue}`, mappedReferences);
  }

  return mappedReferences;
};

const AdditionalSummary = (props: Object) => {
  const { rate, branchConfigs, referenceFormData } = props;

  const { equipment, services } = R.or(rate, {});

  const { pinnedNote, specialInstructions, internalInstructions } = referenceFormData;


  const mappedEquipment = G.getDisplayedValueFromObject(
    getMappedEquipmentFromConfigsByOptionGuid(equipment, branchConfigs),
  );
  const mappedServices = R.map(
    (item: Object) => G.getDisplayedValueFromObject(item),
    getMappedServicesFromConfigsByOptionGuid(services, branchConfigs),
  );
  const mappedReferences = getReferences(props);

  return (
    <Flex flexDirection='column'>
      {
        G.isNotNilAndNotEmpty(mappedReferences) &&
        <ArraySection
          items={mappedReferences}
          dividerText={G.getWindowLocale(
            'titles:references',
            'References',
            { caseAction: 'upperCase' },
          )}
        />
      }
      {
        specialInstructions &&
        <InfoSection
          infoText={specialInstructions}
          dividerText={G.getWindowLocale(
            'titles:special-instructions',
            'Special Instructions',
            { caseAction: 'upperCase' },
          )}
        />
      }
      {
        pinnedNote &&
        <InfoSection
          infoText={pinnedNote}
          dividerText={G.getWindowLocale(
            'titles:note',
            'Note',
            { caseAction: 'upperCase' },
          )}
        />
      }
      {
        internalInstructions &&
        <InfoSection
          infoText={internalInstructions}
          dividerText={G.getWindowLocale(
            'titles:internal-instructions',
            'Internal Instructions',
            { caseAction: 'upperCase' },
          )}
        />
      }
      {
        equipment &&
        <InfoSection
          infoText={mappedEquipment}
          dividerText={G.getWindowLocale(
            'titles:equipment',
            'Equipment',
            { caseAction: 'upperCase' },
          )}
        />
      }
      {
        G.isNotNilAndNotEmpty(mappedServices) &&
        <ArraySection
          items={mappedServices}
          dividerText={G.getWindowLocale(
            'titles:services',
            'Services',
            { caseAction: 'upperCase' },
          )}
        />
      }
      <RateSection {...props} />
    </Flex>
  );
};

export default AdditionalSummary;
