import * as R from 'ramda';
import { FieldArray } from 'formik';
import React, { Fragment } from 'react';
import { pure, compose, withHandlers } from 'react-recompose';
// components
import { ActionBox } from '../../../components/action-box';
// features
import ItemForm from '../../item/components/item-form';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// hocs
import { withVinLookup } from '../../../hocs';
// forms
import { ItemTemplateSearch } from '../../../forms/forms/template-search/components/item-template-search';
// icons
import * as I from '../../../svgs';
// ui
import { Box, Flex, ActionButton, AbsoluteBox, RelativeBox } from '../../../ui';
// feature new-do
import { withOrdersByVin } from '../hocs';
import { Fieldset } from './formik/fieldset';
import { isItemValidFull } from '../validation';
import withMultiVin from '../hocs/with-multi-vin';
import SaveItemTmpl from '../components/save-item-tmpl';
import { getNewItemFields, pickupItemStopGroups, vehicleItemStopGroups } from '../settings/fields-settings';
//////////////////////////////////////////////////

const darkBlueColor = G.getTheme('colors.dark.blue');
const lightRedColor = G.getTheme('colors.light.lightRed');

const enhanceHeader = compose(
  withMultiVin,
);

const Header = enhanceHeader(({ push, branchConfigs, handleOpenMultiVin }: Object) => (
  <Flex px={10} height={30} bg={G.getTheme('colors.bgGrey')}>
    <Box fontWeight={600} color={G.getTheme('colors.greyMatterhorn')}>
      {G.getWindowLocale('titles:items', 'Items')}
    </Box>
    <Box
      mx={10}
      height={15}
      cursor='pointer'
      title={G.getWindowLocale('titles:add-new-item', 'Add new item')}
      onClick={() => push(getNewItemFields(R.or(G.getItemFromWindow('amousNewDoBranchConfigs'), branchConfigs)))}
    >
      {I.plusRound(darkBlueColor)}
    </Box>
    <ActionBox
      action={handleOpenMultiVin}
      text={G.getWindowLocale('titles:multi-vin', 'Multi Vin')}
    />
  </Flex>
));

const searchTemplateItemFieldsOmit = [
  GC.FIELD_ITEM_GUID,
  GC.FIELD_ITEM_VERSION,
  GC.FIELD_ITEM_LOAD_GUID,
  GC.FIELD_ITEM_INTERNAL_ID,
];

const enhance = compose(
  withOrdersByVin,
  withVinLookup(),
  withHandlers({
    handleEditItem: (props: Object) => (initialValues: Object) => {
      const {
        values,
        itemIndex,
        openModal,
        closeModal,
        setFieldValue,
      } = props;

      const componentProps = {
        openModal,
        closeModal,
        initialValues,
        submitAction: (itemValues: Object) => {
          let valuesToUse = itemValues;

          const freightCalculationValues = R.pickAll(GC.GROUPED_FIELDS.FREIGHT_CLASS_CALCULATION_FIELDS, itemValues);

          const condition = R.and(
            G.isAllNotNilOrNotEmpty(R.values(freightCalculationValues)),
            G.isNotNilAndNotEmpty(G.getAmousConfigByNameFromWindow(GC.CLO_ITEM_FREIGHT_CLASS_CALCULATOR)),
          );

          if (condition) {
            valuesToUse = R.assoc(
              GC.FIELD_ITEM_FREIGHT_CLASS,
              G.calculateFreightClass(freightCalculationValues),
              valuesToUse,
            );
          }

          const items = R.prop(GC.FIELD_LOAD_ITEMS, values);
          const updatedItems = R.update(itemIndex, valuesToUse, items);

          setFieldValue(GC.FIELD_LOAD_ITEMS, updatedItems);
          closeModal();
        },
      };

      const component = <ItemForm {...componentProps} />;

      const modal = {
        p: '0',
        component,
        options: {
          width: 'auto',
          height: 'auto',
          title: G.getEditTitle(['titles:item', 'Item']),
        },
      };

      openModal(modal);
    },
    handleSearchItem: (props: Object) => (item: Object) => {
      const { values, openModal, loadGuid, closeModal, branchGuid, itemIndex, divisionGuid, setFieldValue } = props;

      const searchTemplateRequest = (itemValues: Object) => {
        const items = R.prop(GC.FIELD_LOAD_ITEMS, values);
        const newItem = R.mergeRight(
          R.pick([GC.FIELD_ITEM_INTERNAL_ID], item),
          G.mapObjectNullFieldsToEmptyStrings(R.omit(searchTemplateItemFieldsOmit, itemValues)),
        );
        const updatedItems = R.update(itemIndex, newItem, items);
        setFieldValue(GC.FIELD_LOAD_ITEMS, updatedItems);
        closeModal();
      };

      const modalContent = (
        <ItemTemplateSearch
          {...G.getLocationTemplateSearchRequestProps({
            loadGuid,
            branchGuid,
            divisionGuid,
            loadType: GC.LOAD_TYPE_CLO,
          })}
          closeModal={closeModal}
          searchTemplateRequest={searchTemplateRequest}
        />
      );
      const modal = G.createCommonModalOptions(modalContent);

      openModal(modal);
    },
    addItemCopy: ({ push }: Object) => (item: Object) => {
      const itemToUse = G.omitObjectSystemFields(item);
      const itemInternalId = G.generateGuid();
      const itemWithInternalId = R.assoc(GC.FIELD_ITEM_INTERNAL_ID, itemInternalId, itemToUse);
      push(itemWithInternalId);
    },
    handleCustomChange: (props: Object) => (e: Object) => {
      const { values, itemIndex, setFieldValue } = props;
      const { id, value } = e.target;

      setFieldValue(id, value);
      const freightCalculationValues = R.compose(
        R.assoc(R.last(R.split('.', id)), value),
        R.pickAll(GC.GROUPED_FIELDS.FREIGHT_CLASS_CALCULATION_FIELDS),
        R.path([GC.FIELD_LOAD_ITEMS, itemIndex]),
      )(values);
      const condition = R.or(
        G.isAnyNilOrEmpty(R.values(freightCalculationValues)),
        G.isNilOrEmpty(G.getAmousConfigByNameFromWindow(GC.CLO_ITEM_FREIGHT_CLASS_CALCULATOR)),
      );

      if (condition) return;

      setFieldValue(
        `${GC.FIELD_LOAD_ITEMS}.${itemIndex}.${GC.FIELD_ITEM_FREIGHT_CLASS}`,
        G.calculateFreightClass(freightCalculationValues),
      );
    },
  }),
  pure,
);

const notContainGroupRejectOnAdditional = (rejectFieldName: any, additionalFields: Array) => {
  if (G.isArray(rejectFieldName)) {
    let notContain = true;
    R.forEach((fieldName: string) => {
      if (G.isFalse(G.notContain(fieldName, additionalFields))) {
        notContain = false;
      }
    }, rejectFieldName);

    return notContain;
  }

  return G.notContain(rejectFieldName, additionalFields);
};

const getItemFields = (props: Object) => {
  const { item, isOrderQuote, branchConfigs, hideContainerField } = props;

  const requiredConfig = G.ifElse(
    G.isTrue(isOrderQuote),
    GC.CLO_QUOTE_ITEM_REQUIRED_FIELDS,
    GC.CLO_ITEM_REQUIRED_FIELDS,
  );

  const additionalConfig = G.ifElse(
    G.isTrue(isOrderQuote),
    GC.CLO_QUOTE_ITEM_ADDITIONAL_ITEM_FIELDS,
    GC.CLO_UI_ADDITIONAL_ITEM_FIELDS,
  );

  const useDifferentTypes = G.getConfigValueFromStore(GC.CLO_ITEM_USE_DIFFERENT_TYPES, branchConfigs);

  const requiredFields = G.getConfigValueFromStore(requiredConfig, branchConfigs);
  const additionalFields = G.getConfigValueFromStore(additionalConfig, branchConfigs);
  const configFields = R.uniq(R.concat(R.or(requiredFields, []), R.or(additionalFields, [])));

  const stopGroups = G.ifElse(
    R.and(G.isTrue(useDifferentTypes), G.isItemTypeVehicle(item)),
    vehicleItemStopGroups,
    pickupItemStopGroups,
  );

  const groups = R.reject(({ rejectFieldName }: Object) => R.and(
    G.isNotNilAndNotEmpty(rejectFieldName), notContainGroupRejectOnAdditional(rejectFieldName, configFields)),
    R.values(stopGroups),
  );

  const groupsWithFields = R.map((group: Object) => {
    const { fields } = group;

    const newFields = R.compose(
      R.map((item: Object) => {
        const { fieldName, rejectFieldName } = item;

        if (R.includes(fieldName, requiredFields)) return R.assoc('isRequired', true, item);

        if (R.includes(rejectFieldName, requiredFields)) return R.assoc('isRequired', true, item);

        return item;
      }),
      R.reject(({ fieldName, rejectFieldName }: Object) => R.or(
        G.ifElse(hideContainerField, R.equals(GC.FIELD_CONTAINER_INTERNAL_ID, fieldName), false),
        R.and(G.isNotNilAndNotEmpty(rejectFieldName), G.notContain(rejectFieldName, configFields)),
      )),
    )(fields);

    return R.assoc('fields', newFields, group);
  }, groups);

  return R.reject(({ fields }: Object) => G.isNilOrEmpty(fields), groupsWithFields);
};

const ItemRow = enhance((props: Object) => {
  const {
    item,
    branchGuid,
    addItemCopy,
    handleEditItem,
    handleVinLookup,
    handleSearchItem,
    handleCustomChange,
    handleOpenOrdersByVin,
  } = props;

  const itemFormInvalid = R.not(isItemValidFull(props, item));

  return (
    <Fragment>
      {
        itemFormInvalid &&
        <Box pt={15} pl={15} color={lightRedColor}>
          {G.getWindowLocale('messages:invalid-item', 'Invalid item. Please verify the item fields')}
        </Box>
      }
      <Flex my='25px' alignItems='baseline'>
        <Flex flexDirection='column'>
          <Flex>
            <Box>
              <Flex
                ml={10}
                display='flex'
                cursor='pointer'
                alignItems='center'
                title={G.getWindowLocale('titles:remove', 'Remove')}
                onClick={() => {
                  const { item, remove, itemIndex, removeItemFromStore } = props;

                  remove(itemIndex);
                  G.callFunctionWithArgs(removeItemFromStore, R.path([GC.FIELD_ITEM_INTERNAL_ID], item));
                }}
              >
                {I.trash(darkBlueColor)}
              </Flex>
            </Box>
            <Box>
              <Flex
                ml={10}
                display='flex'
                cursor='pointer'
                alignItems='center'
                onClick={() => handleSearchItem(item)}
                title={G.getWindowLocale('titles:search', 'Search')}
              >
                {I.globalSearch2(darkBlueColor)}
              </Flex>
            </Box>
            <RelativeBox>
              <Flex
                ml={10}
                display='flex'
                cursor='pointer'
                alignItems='center'
                onClick={() => handleEditItem(item)}
                title={G.getWindowLocale('titles:edit', 'Edit')}
              >
                {I.pencil(itemFormInvalid ? lightRedColor : darkBlueColor)}
              </Flex>
              {
                itemFormInvalid &&
                <AbsoluteBox
                  top={-20}
                  right={-5}
                  fontSize={18}
                  cursor='pointer'
                  color={lightRedColor}
                  onClick={() => handleEditItem(item)}
                  title={G.getWindowLocale('messages:click-icon-to-full-edit', 'Click icon to full edit')}
                >
                  !
                </AbsoluteBox>
              }
            </RelativeBox>
            <Box>
              <Flex
                mx={10}
                display='flex'
                cursor='pointer'
                alignItems='center'
                onClick={() => addItemCopy(item)}
                title={G.getWindowLocale('titles:copy', 'Copy')}
              >
                {I.copy(darkBlueColor)}
              </Flex>
            </Box>
            <Box>
              <SaveItemTmpl
                item={item}
                branchGuid={branchGuid}
                initialValue={R.path(['item', GC.FIELD_ITEM_ID], props)}
              />
            </Box>
          </Flex>
          {
            G.isItemTypeVehicle(item) &&
            <Box mt={15}>
              <ActionButton
                width={100}
                height={20}
                fontSize={12}
                type='button'
                minWidth='unset'
                borderRadius='3px'
                onClick={() => handleVinLookup(item)}
              >
                {G.getWindowLocale('actions:vin-lookup', 'VIN Lookup')}
              </ActionButton>
            </Box>
          }
          {
            G.isItemTypeVehicle(item) &&
            <Box mt={15}>
              <ActionButton
                width={100}
                height={20}
                fontSize={12}
                type='button'
                minWidth='unset'
                borderRadius='3px'
                onClick={() => handleOpenOrdersByVin(item)}
              >
                {G.getWindowLocale('actions:duplicate-vin', 'Duplicate VIN')}
              </ActionButton>
            </Box>
          }
        </Flex>
        <Fieldset
          {...props}
          grouped={true}
          fieldsetType='array'
          fields={getItemFields(props)}
          arrayName={GC.FIELD_LOAD_ITEMS}
          handleCustomChange={handleCustomChange}
        />
      </Flex>
    </Fragment>
  );
});

const ItemsArray = (props: Object) => {
  const items = props.values.items;

  return (
    <Box mt='20px'>
      <FieldArray
        name={GC.FIELD_LOAD_ITEMS}
        render={(arrayHelpers: Object) => (
          <Box>
            <Header {...props} push={arrayHelpers.push} />
            <Box>
              {
                R.gt(R.length(items), 0)
                && items.map((item: string, i: number) => (
                  <ItemRow {...props} {...arrayHelpers} key={i} item={item} itemIndex={i} />
                ))
              }
            </Box>
            {
              R.gt(R.length(items), 2) &&
              <Header {...props} push={arrayHelpers.push} />
            }
          </Box>
        )}
      />
    </Box>
  );
};

export default ItemsArray;
