import React from 'react';
import * as R from 'ramda';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
// components
import { FormGroupTitleMultiple } from '../../../components/form-group-title-multiple';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// ui
import { Box, Flex, StickedBox } from '../../../ui';
// features rate/driver
import { getTotalDistanceFromValues } from '../helpers';
//////////////////////////////////////////////////

const calcTripRateTotal = (rate: Object) => {
  if (G.isNilOrEmpty(rate)) return 0;

  const {
    currency,
    fleetVendorCharges,
    primaryDriverCharges,
    secondaryDriverCharges,
  } = rate;

  const options = { rateCurrency: currency, omitAdvancePayment: true };

  const vendorTotal = G.calcChargesTotal(fleetVendorCharges, options);

  const primaryTotal = G.calcChargesTotal(primaryDriverCharges, options);

  const secondaryTotal = G.calcChargesTotal(secondaryDriverCharges, options);

  return R.sum([vendorTotal, primaryTotal, secondaryTotal]);
};

const getTripTotalInfo = (props: Object) => {
  const { values, activeDriver } = props;

  const totalDistance = getTotalDistanceFromValues(values);

  if (G.isNilOrEmpty(totalDistance)) return null;

  const currencySymbol = G.getCurrencySymbolFromRate(values);

  const totalRate = calcTripRateTotal(values, activeDriver);

  const rpm = G.getRpmByRateAndDistance(totalRate, totalDistance);

  if (G.isNilOrEmpty(rpm)) return null;

  return `${currencySymbol} ${G.mathRoundNumber(totalRate)} (${rpm})`;
};

const getCustomerTotalInfo = (props: Object) => {
  const { values } = props;

  const { customerTotal } = values;

  if (G.isNilOrEmpty(customerTotal)) return null;

  const totalDistance = getTotalDistanceFromValues(values);

  const currencySymbol = G.getCurrencySymbolFromRate(values);

  const rpm = G.getRpmByRateAndDistance(customerTotal, totalDistance);

  if (G.isNilOrEmpty(rpm)) return null;

  return `${currencySymbol} ${G.mathRoundNumber(customerTotal)} (${rpm})`;
};

const getTripAndCustomerTotalInfo = (props: Object) => {
  const tripInfo = getTripTotalInfo(props);

  const customerInfo = getCustomerTotalInfo(props);

  if (R.and(G.isNilOrEmpty(tripInfo), G.isNilOrEmpty(customerInfo))) return null;

  const tripLocale = G.getWindowLocale('titles:trip', 'Trip');
  const customerLocale = G.getWindowLocale('titles:customer', 'Customer');

  if (G.isNilOrEmpty(customerInfo)) {
    return `${tripLocale}: ${tripInfo}`;
  }

  if (G.isNilOrEmpty(tripInfo)) {
    return `${customerLocale}: ${customerInfo}`;
  }

  return `${tripLocale}: ${tripInfo}, ${customerLocale}: ${customerInfo}`;
};

const getChargesTabTotalInfo = (props: Object, chargesProp: string) => {
  const { values } = props;

  const currency = G.getCurrencyFromObject(values);
  const currencySymbol = G.getCurrencySymbolFromRate(values);

  const charges = R.path([chargesProp], values);
  const totalRate = G.getCalcTelRateChargesTotalPay(charges, currency);

  const totalWithCurrency = `${currencySymbol} ${totalRate}`;

  const totalDistance = getTotalDistanceFromValues(values);

  const rpm = G.getRpmByRateAndDistance(totalRate, totalDistance);

  if (G.isNilOrEmpty(rpm)) return totalWithCurrency;

  return `${totalWithCurrency} (${rpm})`;
};

const hasSecondaryDriver = (props: Object) => G.isNotNilAndNotEmpty(
  R.path(['values', GC.FIELD_SECONDARY_DRIVER_GUID], props),
);

const hasVendor = (props: Object) => R.or(
  G.isNotNilAndNotEmpty(R.path(['values', 'fleetVendorCharges'], props)),
  G.isNotNilAndNotEmpty(props.truckFleetVendor),
);

const tabStyles = {...GC.COMMON_MUI_TAB_STYLES, fontSize: 11};

const RateHeader = (props: Object) => {
  const {
    mode,
    activeDriver,
    handleChangeDriver,
  } = props;

  const handleChangeTab = (event: Object, newValue: string) => handleChangeDriver(newValue);

  const title = G.ifElse(
    R.equals(mode, 'create'),
    G.getWindowLocale('titles:add-rate', 'Add Rate'),
    G.getWindowLocale('titles:edit-rate', 'Edit Rate'),
  );

  const totalInfo = getTripAndCustomerTotalInfo(props);

  return (
    <StickedBox top='0px' zIndex={15}>
      <FormGroupTitleMultiple
        mb='0'
        height={35}
        title={title}
        jc='space-between'
        showArrowToggle={false}
      />
      <Flex
        height={38}
        p='7px 15px'
        alignItems='center'
        justifyContent='space-between'
        bg={G.getTheme('modal.bgColor')}
      >
        <Tabs value={activeDriver} onChange={handleChangeTab} sx={GC.COMMON_MUI_TABS_STYLES}>
          <Tab
            value='primary'
            sx={tabStyles}
            label={`
              ${G.getWindowLocale('titles:primary-driver', 'Primary Driver')}:
              ${getChargesTabTotalInfo(props, GC.FIELD_PRIMARY_DRIVER_CHARGES)}
            `}
          />
          {
            hasSecondaryDriver(props) &&
            <Tab
              value='team'
              sx={tabStyles}
              label={`
                ${G.getWindowLocale('titles:team-driver', 'Team Driver')}:
                ${getChargesTabTotalInfo(props, GC.FIELD_SECONDARY_DRIVER_CHARGES)}
              `}
            />
          }
          {
            hasVendor(props) &&
            <Tab
              value='vendor'
              sx={tabStyles}
              label={`
                ${G.getWindowLocale('titles:vendor', 'Vendor')}:
                ${getChargesTabTotalInfo(props, GC.FIELD_FLEET_VENDOR_CHARGES)}
              `}
            />
          }
        </Tabs>
        {
          totalInfo &&
          <Box
            p='5px'
            fontSize={11}
            bg='titleDarkBlue'
            borderRadius='4px'
            color={G.getTheme('colors.white')}
          >
            {getTripAndCustomerTotalInfo(props)}
          </Box>
        }
      </Flex>
    </StickedBox>
  );
};

export default RateHeader;
