import React from 'react';
import * as R from 'ramda';
import { compose, lifecycle, withHandlers } from 'react-recompose';
// components
import { InfoPair } from '../../../components/info-pair';
import { LeafletMap } from '../../../components/leaflet-map';
import { StopInfo, StopMarker } from '../../../components/map/';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// ui
import { Box, Flex, Text } from '../../../ui';
// feature new-do
import Tabs from './tabs';
import EdiContent from './edi-content';
import RatePreview from './rate-preview';
import {
  isPageCreateFromEdi,
  getRightSectionWidth,
  renderLeftRightContent,
  renderLeftRightEmptyInfo,
} from '../helpers';
import AdditionalSummary from './additional-summary';
import StopsSummaryWithDnD from './stops-summary-with-dnd';
import {
  TAB_NAME_MAP,
  FORM_BOX_SHADOW,
  WAITING_PICTURE_SRC,
  TAB_NAME_CLO_SUMMARY,
  TAB_NAME_RATE_CONFIRMATION,
} from '../constants';
//////////////////////////////////////////////////

const tabSettings = [
  {
    name: TAB_NAME_CLO_SUMMARY,
    text: G.getWindowLocale('titles:clo-summary', 'CLO Summary'),
  },
  {
    name: TAB_NAME_RATE_CONFIRMATION,
    text: G.getWindowLocale('titles:rate-confirmation', 'Rate Confirmation'),
  },
  {
    name: TAB_NAME_MAP,
    text: G.getWindowLocale('titles:map', 'Map'),
  },
];
const getLocationsWithMarkers = (locations: Array) => R.map((location: Object) => {
  const { title } = location;

  const pickup = R.equals(R.path(['stop', 'eventType'], location), GC.EVENT_TYPE_PICKUP);
  const eventEarlyDate = R.path(['stop', 'formData', GC.FIELD_LOAD_EVENT_EARLY_DATE], location);

  return R.mergeRight(location, {
    markerContent: <StopMarker {...location} />,
    color: G.getTheme('map.completedMarkerColor'),
    markerTitle: `${R.head(title)}${G.getNumberFromString(title)}`,
    infoContent: <StopInfo {...location} pickup={pickup} eventEarlyDate={eventEarlyDate} />,
  });
}, locations);

const mapEnhance = lifecycle({
  shouldComponentUpdate(nextProps: Object) {
    const { mapLocations } = this.props;

    return G.ifElse(R.equals(mapLocations, nextProps.mapLocations), false, true);
  },
});

export const MapLocations = mapEnhance((props: Object) => {
  const { mapLocations } = props;

  const center = R.path(['latLng'], R.head(mapLocations));

  const directionsColor = G.getTheme('map.directionsColor');

  const wrapperProps = {
    width: '100%',
    height: '100%',
    boxShadow: G.getTheme('shadows.standard'),
  };

  const routes = [
    { waypoints: [] },
    { color: directionsColor, waypoints: mapLocations.map((location: Object) => location.latLng) },
  ];

  const locationsWithMarkers = getLocationsWithMarkers(mapLocations);

  return (
    <Box {...wrapperProps}>
      { G.isNotNilAndNotEmpty(center) && (
        <LeafletMap
          routes={routes}
          center={center}
          height='calc(100vh - 245px)'
          withDynamicWaypoints={true}
          locations={locationsWithMarkers}
        />
      )}
    </Box>
  );
});

export const TransportingCarrier = ({ text }: Object) => (
  <Flex mx={15}>
    <Box>
      {G.getWindowLocale('titles:transporting-carrier', 'Transporting Carrier')}:
    </Box>
    <Box fontWeight='bold' ml={10}>
      {text}
    </Box>
  </Flex>
);

const integrationCarrierStyles = {
  fontSize: 14,
  margin: '0 15px',
  textMaxWidth: 500,
};

const renderTabContent = (props: Object) => {
  const { pageType, rightActiveTad } = props;

  if (R.equals(rightActiveTad, TAB_NAME_CLO_SUMMARY)) {
    return (
      <Box>
        <StopsSummaryWithDnD {...props} />
        <InfoPair
          {...integrationCarrierStyles}
          label={G.getWindowLocale('titles:transporting-carrier', 'Transporting Carrier')}
          text={R.path(['integrationCarriers', GC.FIELD_TRANSPORTING_CARRIER_NAME], props)}
        />
        <InfoPair
          {...integrationCarrierStyles}
          label={G.getWindowLocale('titles:origin-carrier-name', 'Origin Carrier Name')}
          text={R.path(['integrationCarriers', GC.FIELD_ORIGIN_CARRIER_NAME], props)}
        />
        <InfoPair
          {...integrationCarrierStyles}
          text={R.path(['integrationCarriers', GC.FIELD_DESTINATION_CARRIER_NAME], props)}
          label={G.getWindowLocale('titles:destination-carrier-name', 'Destination Carrier Name')}
        />
        <AdditionalSummary {...props} />
      </Box>
    );
  }

  if (R.and(
    isPageCreateFromEdi(pageType),
    R.equals(rightActiveTad, TAB_NAME_RATE_CONFIRMATION),
  )) {
    return <EdiContent {...props} />;
  }

  if (R.equals(rightActiveTad, TAB_NAME_RATE_CONFIRMATION)) {
    return <RatePreview {...props} />;
  }

  if (R.equals(rightActiveTad, TAB_NAME_MAP)) {
    return <MapLocations {...props} />;
  }

  return null;
};

export const RightSectionEmptyInfo = () => (
  <Box p='50px' boxShadow={FORM_BOX_SHADOW}>
    <Flex mt='16px' mb='50px' justifyContent='center'>
      <img width={150} src={WAITING_PICTURE_SRC} alt='Waiting For Information' />
    </Flex>
    <Flex flexDirection='column'>
      <Text pb='15px' fontSize='16px' color={G.getTheme('colors.dark.grey')}>
        {G.getWindowLocale('messages:no-information', 'There is no information yet')}
      </Text>
      <Text fontSize='16px' color={G.getTheme('colors.dark.grey')}>
        {G.getWindowLocale('messages:fill-information', 'Please, start filling out information')}
      </Text>
    </Flex>
  </Box>
);

const RightSection = (props: Object) => {
  const { leftActiveTad, rightActiveTad, onClickTabCallback } = props;

  return (
    <Box
      flexShrink={0}
      minWidth={662}
      maxWidth={1200}
      borderRadius='4px'
      bg={G.getTheme('colors.white')}
      width={getRightSectionWidth(leftActiveTad, rightActiveTad)}
    >
      {
        renderLeftRightContent(props) &&
        <Tabs
          overflow='auto'
          options={tabSettings}
          activeFromParent={rightActiveTad}
          onClickTabCallback={onClickTabCallback}
          defaultActiveName={TAB_NAME_CLO_SUMMARY}
        />
      }
      {
        renderLeftRightEmptyInfo(props) && <RightSectionEmptyInfo />
      }
      {renderLeftRightContent(props) && renderTabContent(props)}
    </Box>
  );
};

const enhance = compose(
  withHandlers({
    onClickTabCallback: ({ setActiveRightTab }: Object) => (activeName: Object) => {
      setActiveRightTab(activeName);
    },
  }),
);

export default enhance(RightSection);
