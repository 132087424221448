import * as R from 'ramda';
import { put, call, select, takeLatest } from 'redux-saga/effects';
// components
import { openLoader, closeLoader } from '../../components/loader/actions';
import {
  checkReportFunction,
  transformSearchCriteriaBeforeReportPost,
} from '../../components/edit-report/helpers';
// features
import { makeSelectCurrentBranchGuid } from '../branch/selectors';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// report-common
import { generateDefaultReport } from '../../report-common';
// utilities
import { sendRequest } from '../../utilities/http';
import endpointsMap from '../../utilities/endpoints';
// feature pivot-table
import * as A from './actions';
import {
  makeSelectUsedReport,
  makeSelectAvailableReports,
  makeSelectPivotTableReportType,
  makeSelectDefaultSearchCriteria,
} from './selectors';
//////////////////////////////////////////////////

const mapReportResultsWithProperUomSystem = (data: Object) => {
  if (G.isNilOrEmpty(data)) return data;

  const results = R.pathOr([], ['results'], data);
  const isMetricDefaultUomSystem = R.equals(GC.METRIC_SYSTEM, G.getConfigGeneralUomCalcDefaultUomSystemFromWindow());
  const firstObject = R.pathOr({}, [0], results);

  if (R.or(G.isTrue(isMetricDefaultUomSystem), G.isNilOrEmpty(firstObject))) return data;

  const newResults = R.map(
    (item: Object) => {
      const newItem = {...item};
      R.forEachObjIndexed((value: any, key: string) => {
        if (R.and(R.includes('weight', R.toLower(key)), G.isNumber(value))) {
          newItem[key] = G.fromKgsToPounds(value, 2);
        }

        if (R.and(R.includes('distance', R.toLower(key)), G.isNumber(value))) {
          newItem[key] = G.fromKmsToMilesNumber(value, 2);
        }
      }, item);

      return newItem;
    },
    results,
  );

  return R.assoc('results', newResults, data);
};

const mapReportResultsWithoutGuids = (data: Object, reportType: string) => {
  if (G.isNilOrEmpty(data)) return data;

  const results = R.pathOr([], ['results'], data);

  let fieldsToOmit = [GC.FIELD_GUID, GC.FIELD_CLO_GUID, GC.FIELD_TEL_GUID, GC.FIELD_EDITABLE];

  if (R.includes(reportType, [GC.PIVOT_CLO_REPORT, GC.PIVOT_TEL_REPORT])) {
    fieldsToOmit = R.tail(fieldsToOmit);
  }

  const newResults = R.map(R.omit(fieldsToOmit), results);

  return R.assoc('results', newResults, data);
};

const getEquipmentServicePivotTableData = (data: Object) => R.assoc(
  'results',
  R.map(
    (item: Object) => {
      const relatedTasks = G.getPropFromObject(GC.FIELD_RELATED_TASKS, item);

      if (G.isNilOrEmpty(relatedTasks)) return item;

      return {
        ...item,
        [GC.FIELD_RELATED_TASKS]: R.map(R.prop(GC.FIELD_TASK_NUMBER), relatedTasks),
      };
    },
    R.pathOr([], ['results'], data),
  ),
  data,
);

function* handleGetPivotTableDataSaga() {
  try {
    yield put(openLoader());

    const report = yield select(makeSelectUsedReport());
    const reportType = yield select(makeSelectPivotTableReportType());
    const availableReports = yield select(makeSelectAvailableReports());

    if (R.and(
      G.isNilOrEmpty(availableReports),
      R.includes('Default', report.guid),
    )) {
      return yield put(closeLoader());
    }

    const currentBranchGuid = yield select(makeSelectCurrentBranchGuid());

    let searchCriteria = G.getOrElse(report, 'searchCriteria', []);

    if (R.includes(reportType, [GC.PIVOT_FUEL_CARD_REPORT, GC.PIVOT_FLEET_EQUIPMENT_SERVICE_REPORT])) {
      const defaultSearchCriteria = yield select(makeSelectDefaultSearchCriteria());

      searchCriteria = R.concat(defaultSearchCriteria, searchCriteria);
    }

    const reqBody = {
      pageable: false,
      fields: G.getOrElse(report, 'fields', []),
      [GC.FIELD_CURRENT_BRANCH]: currentBranchGuid,
      searchCriteria: transformSearchCriteriaBeforeReportPost(searchCriteria),
      [GC.FIELD_AVOID_RELATED_VALUE_DUPLICATES]: R.pathOr(false, [GC.FIELD_AVOID_RELATED_VALUE_DUPLICATES], report),
    };

    const options = {
      data: reqBody,
    };

    const pivotEndpoints = {
      [GC.CLO_REPORT]: endpointsMap.cloList,
      [GC.TEL_REPORT]: endpointsMap.telList,
      [GC.PIVOT_CLO_REPORT]: endpointsMap.cloList,
      [GC.PIVOT_TEL_REPORT]: endpointsMap.telList,
      [GC.PIVOT_FUEL_CARD_REPORT]: endpointsMap.fuelCardList,
      [GC.PIVOT_ROUTE_BY_LOAD_REPORT]: endpointsMap.routeListByLoad,
      [GC.PIVOT_DRIVER_PAYROLL_REPORT]: endpointsMap.driverPayrollList,
      [GC.PIVOT_VENDOR_PAYROLL_REPORT]: endpointsMap.vendorPayrollList,
      [GC.PIVOT_CUSTOMER_INVOICE_REPORT]: endpointsMap.customerInvoiceList,
      [GC.PIVOT_PAYROLL_CHARGES_REPORT]: endpointsMap.driverPayrollListCharges,
      [GC.PIVOT_VENDOR_PAYROLL_CHARGES_REPORT]: endpointsMap.vendorPayrollListCharges,
      [GC.PIVOT_FLEET_EQUIPMENT_SERVICE_REPORT]: endpointsMap.fleetEquipmentServiceList,
    };

    const res = yield call(sendRequest, 'post', pivotEndpoints[reportType], options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      const pivotTableData = mapReportResultsWithProperUomSystem(mapReportResultsWithoutGuids(data, reportType));

      if (R.equals(reportType, GC.PIVOT_FLEET_EQUIPMENT_SERVICE_REPORT)) {
        yield put(A.getPivotTableDataSuccess(getEquipmentServicePivotTableData(pivotTableData)));
      } else {
        yield put(A.getPivotTableDataSuccess(pivotTableData));
      }
    } else {
      yield call(G.handleFailResponse, res, 'handleGetPivotTableDataSaga fail');
    }

    yield put(closeLoader());
  } catch (err) {
    yield put(closeLoader());
    yield call(G.handleException, err, 'handleGetPivotTableDataSaga exception');
  }
}

function* handleAvailableReportsRequest() {
  try {
    const currentBranchGuid = yield select(makeSelectCurrentBranchGuid());

    if (G.isNilOrEmpty(currentBranchGuid)) return false;

    const reportType = yield select(makeSelectPivotTableReportType());

    const params = {
      reportType,
      [GC.FIELD_CURRENT_BRANCH]: currentBranchGuid,
    };

    const res = yield call(sendRequest, 'get', endpointsMap.listReports, { params });

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      const reports = checkReportFunction(data);

      yield put(A.setReports(reports));
    } else {
      yield call(G.handleFailResponse, res, 'handleAvailableReportsRequest fail');
    }
  } catch (error) {
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleAvailableReportsRequest exception');
  }
}

function* handleCreateReportRequestSaga({ payload }: Object) {
  try {
    yield put(openLoader());

    const reportType = yield select(makeSelectPivotTableReportType());
    const currentBranchGuid = yield select(makeSelectCurrentBranchGuid());

    const searchCriteria = R.pathOr([], [GC.FIELD_SEARCH_CRITERIA], payload);

    const transformedSearchCriteria = R.map(
      R.assoc(GC.FIELD_GUID, null),
      transformSearchCriteriaBeforeReportPost(searchCriteria),
    );

    const reqBody = R.mergeRight(payload, {
      [GC.FIELD_TYPE]: reportType,
      [GC.FIELD_BRANCH_GUID]: currentBranchGuid,
      [GC.FIELD_SEARCH_CRITERIA]: transformedSearchCriteria,
    });

    const options = {
      data: reqBody,
    };

    const res = yield call(sendRequest, 'post', endpointsMap.reportPivot, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      const report = R.head(checkReportFunction(R.of(Array, R.assoc(GC.FIELD_SEARCH_CRITERIA, searchCriteria, data))));

      yield put(A.setUsedReport(report));

      yield call(handleAvailableReportsRequest);
      yield call(handleGetPivotTableDataSaga);
    } else {
      yield call(G.handleFailResponse, res, 'handleCreateReportRequestSaga fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleCreateReportRequestSaga exception');
  }
}

function* handleUpdateReportRequestSaga({ payload }: Object) {
  try {
    yield put(openLoader());

    const searchCriteria = R.pathOr([], [GC.FIELD_SEARCH_CRITERIA], payload);
    const transformedSearchCriteria = transformSearchCriteriaBeforeReportPost(searchCriteria);

    const options = {
      data: R.assoc(GC.FIELD_SEARCH_CRITERIA, transformedSearchCriteria, payload),
    };

    const res = yield call(sendRequest, 'put', endpointsMap.reportPivot, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      const report = R.head(checkReportFunction(R.of(Array, R.assoc(GC.FIELD_SEARCH_CRITERIA, searchCriteria, data))));

      yield put(A.setUsedReport(report));

      yield call(handleAvailableReportsRequest);
      yield call(handleGetPivotTableDataSaga);
    } else {
      yield call(G.handleFailResponse, res, 'handleUpdateReportRequestSaga fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleUpdateReportRequestSaga exception');
  }
}

function* handleChangeDefaultReportSaga({ payload }: Object) {
  try {
    yield put(openLoader());

    // TODO: change endpoint
    const res = yield call(sendRequest, 'put', endpointsMap.changeDefaultReport, { data: payload });

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      yield call(handleAvailableReportsRequest);
    } else {
      yield call(G.handleFailResponse, res, 'handleChangeDefaultReportSaga fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.handleException, error, 'handleChangeDefaultReportSaga exception');
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
  }
}

function* startPivotTableSaga({ payload }: Object) {
  while (true) { // eslint-disable-line
    const reportType = R.pathOr(payload, [GC.REPORT_TYPE], payload);

    yield put(openLoader({
      showDimmer: G.notContain(reportType, [GC.PIVOT_FUEL_CARD_REPORT, GC.PIVOT_FLEET_EQUIPMENT_SERVICE_REPORT]),
    }));

    yield put(A.setInitialState());
    yield put(A.setPivotReportType(reportType));

    const defaultSearchCriteria = R.pathOr([], ['defaultSearchCriteria'], payload);

    if (G.isNotNilAndNotEmpty(defaultSearchCriteria)) yield put(A.setDefaultSearchCriteria(defaultSearchCriteria));

    yield call(handleAvailableReportsRequest, { payload: reportType });

    const reports = yield select(makeSelectAvailableReports());

    let defaultReport = G.findDefaultReport(reports);

    if (R.isNil(defaultReport)) defaultReport = generateDefaultReport(reportType);

    yield put(A.setUsedReport(defaultReport));

    yield call(handleGetPivotTableDataSaga);

    yield put(closeLoader());

    break;
  }
}

function* pivotTableWatcherSaga() {
  yield takeLatest(A.startPivotTable, startPivotTableSaga);
  yield takeLatest(A.createReportRequest, handleCreateReportRequestSaga);
  yield takeLatest(A.updateReportRequest, handleUpdateReportRequestSaga);
  yield takeLatest(A.getPivotTableDataRequest, handleGetPivotTableDataSaga);
  yield takeLatest(A.changeDefaultReportRequest, handleChangeDefaultReportSaga);
}

export default pivotTableWatcherSaga;
